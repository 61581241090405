@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply text-2xl font-bold;
    }
    h2 {
        @apply text-xl font-bold;
    }
    h3 {
        @apply text-lg font-bold;
    }
    h4 {
        @apply font-bold;
    }
    code {
        @apply bg-gray-800 p-0.5
    }
    a.md-link {
        font-weight: bold;
        background: -webkit-linear-gradient(rgb(134 239 172), #67e8f9);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

@layer components {

    .sidebar-icon {
        @apply relative flex items-center justify-center
                h-8 w-8 mt-4 mb-4 mx-auto
                text-white text-opacity-20 rounded-full
                hover:text-opacity-40;
    }

    .sidebar-tooltip {
        @apply absolute w-auto p-2 m-2 min-w-max left-10
        rounded-md shadow-md
        text-white bg-gray-800
        text-xs font-bold
        transition-all duration-100 scale-0 origin-left;
    }
    
    .icon {
        @apply relative flex items-center justify-center
                h-8 w-8 mx-auto
                text-white text-opacity-20 rounded-full
                hover:text-opacity-40 hover:animate-pulse;
    }

}
